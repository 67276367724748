@tailwind base;
@tailwind components;
@tailwind utilities;

.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}
.overflow-x-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
